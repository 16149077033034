// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/constants/fileSize.ts"
);
import.meta.hot.lastModified = "1729076157000";
}
// REMIX HMR END

export const fileSize = {
  '30MB': 1024 * 1024 * 30,
  '10MB': 1024 * 1024 * 10,
  '15MB': 1024 * 1024 * 15,
  '1MB': 1024 * 1024,
};